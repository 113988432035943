<template>
  <section>
    <div
      class="bg-gray-100 font-extralight text-brand-text"
      v-if="diamond.form"
    >
      <NextStep
        :diamond="diamond"
        :modal-visible="showNextStepModal"
        @update:modalVisible="showNextStepModal = $event"
      />

      <details-fullscreen-image
        :diamond-form="diamond.form"
        :diamond-institute="diamond.institute"
        :diamond-certificate="diamond.certificate"
        :diamond-image="diamond.image_url || ''"
        :modal-visible="imageFullscreenMode"
        @update:modalVisible="imageFullscreenMode = $event"
      />

      <details-fullscreen-video
        :diamond-url="diamond.video_url"
        :diamond-form="diamond.form"
        :modal-visible="videoFullscreenMode"
        @update:modalVisible="videoFullscreenMode = $event"
      />

      <div class="bg-white">
        <div class="max-w-screen-sm mx-auto md:grid md:grid-cols-5 mb-4 px-8">
          <div class="relative md:col-span-3">
            <details-image
              :diamond-form="diamond.form"
              :diamond-institute="diamond.institute"
              :diamond-image="diamond.image_url || ''"
              :diamond-certificate="diamond.certificate"
              show-viewer="true"
            />
            <svg
              @click="imageFullscreenMode = true"
              xmlns="http://www.w3.org/2000/svg"
              class="absolute cursor-pointer z-1 bottom-2 right-2 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
              />
            </svg>
          </div>
          <div class="md:order-last md:w-44">
            <br />
          </div>
          <div
            class="md:order-first flex md:flex-wrap gap-x-2 md:gap-x-0 md:gap-y-1 md:pr-2"
          >
            <div
              class="flex-1 md:flex-none flex md:w-full md:h-1/3 border border-1"
            >
              <a
                :href="certificateUrl"
                target="_blank"
                class="my-auto cursor-pointer w-full"
              >
                <img
                  v-if="diamond.institute === 'GIA'"
                  :src="Images.get('/img/filter/institute/gia_icon.svg')"
                  alt=""
                  class="w-full p-2"
                />
                <img
                  v-else-if="diamond.institute === 'IGI'"
                  :src="Images.get('/img/filter/institute/igi_icon.svg')"
                  alt=""
                  class="w-full p-2"
                />
                <img
                  v-else-if="diamond.institute === 'HRD'"
                  :src="Images.get('/img/filter/institute/hrd_icon.svg')"
                  alt=""
                  class="w-full"
                />
              </a>
            </div>
            <div class="flex-1 md:flex-none md:w-full md:h-1/3 border border-1">
              <img
                @click="toConfidence"
                :src="
                  Images.get('/img/diamond_details_images_sustainable@1x.png')
                "
                :srcset="
                  Images.get('/img/diamond_details_images_sustainable@2x.png')
                "
                alt=""
                class="w-full my-auto cursor-pointer"
              />
            </div>
            <div class="flex-1 md:flex-none md:w-full md:h-1/3 border border-1">
              <img
                @click="videoFullscreenMode = true"
                :src="Images.get('/img/diamond_details_images_360@1x.png')"
                :srcset="Images.get('/img/diamond_details_images_360@2x.png')"
                alt=""
                class="w-full my-auto cursor-pointer"
              />
            </div>
          </div>
        </div>

        <!-- Info -->

        <div class="max-w-2xl mx-auto text-center">
          <headline class="pb-2">
            {{ $t("details.form." + diamond.form) }}
            {{ $n(diamond.size, "carat") }} ct.
          </headline>
          <p
            class="mb-4 text-sm divide-x divide-gray-600 flex justify-center tracking-widest"
          >
            <span class="px-4">
              {{ $t("details.head.color") }}: {{ diamond.color }}
            </span>
            <span class="px-4">
              {{ $t("details.head.clarity") }}: {{ diamond.clarity }}
            </span>
            <span class="px-4" v-if="diamond.form == 'BR'">
              {{ $t("details.head.cut") }}: {{ diamond.cut }}
            </span>
          </p>
          <div class="text-3xl justify-center my-4 text-center">
            <p class="relative max-w-max inline-block">
              <span :class="{ 'text-red-600': diamond.special }">{{
                $n(diamond.price, "currency")
              }}</span>
              <button
                v-if="false"
                type="button"
                class="absolute top-0 h-full -right-12 rounded-md flex px-2 items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                :class="{
                  'text-red-600': favorites.includes(
                    diamond.institute.toUpperCase() + diamond.certificate
                  ),
                }"
                @click="toggleFavorite(diamond.institute + diamond.certificate)"
              >
                <!-- Heroicon name: outline/heart -->
                <svg
                  class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  :fill="
                    favorites.includes(
                      diamond.institute.toUpperCase() + diamond.certificate
                    )
                      ? 'currentColor'
                      : 'none'
                  "
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                  />
                </svg>
                <span class="sr-only">Add to favorites</span>
              </button>
            </p>
          </div>
        </div>

        <!-- CTA -->

        <div
          class="max-w-lg px-8 md:px-0 mx-auto text-center grid grid-cols-1 md:grid-cols-1 gap-2"
        >
          <form
            method="post"
            action="https://www.rubin-ec84.de:7004/store/faces/login.xhtml"
            target="_blank"
          >
            <button
              type="submit"
              class="w-full uppercase text-sm bg-brand-main text-white border border-brand-main py-1 px-8 flex items-center justify-center hover:text-brand-text hover:bg-brand-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-brand-main"
            >
              {{ $t("details.head.button.buyJewelry") }}
            </button>
            <input type="hidden" name="lang" value="de" />
            <input type="hidden" name="action" value="order" />
            <input type="hidden" name="order" :value="orderDataJewelry" />
          </form>
        </div>

        <!-- full specs -->

        <separation-line />

        <details-full-specs :diamond="diamond" class="px-8" />

        <!-- Details -->

        <details-carat
          class="py-4 md:py-8"
          :carat="diamond.size"
          :form="diamond.form"
          :width="diamond.width"
          :length="diamond.length"
        />
        <details-color class="py-4 md:py-8" :color="diamond.color" />
        <details-clarity class="py-4 md:py-8" :clarity="diamond.clarity" />
        <details-cut
          v-if="diamond.form == 'BR'"
          class="py-4 md:py-8"
          :cut="diamond.cut"
        />
        <details-fluorescence
          class="py-4 md:py-8"
          :fluorescence="diamond.fluorescence"
        />

        <!-- Text -->

        <div
          class="max-w-screen-lg mx-auto md:flex my-6 font-extralight"
          id="textConfidence"
        >
          <div class="px-8 md:px-0 md:flex-1 flex flex-wrap justify-center">
            <img
              :src="Images.get('/img/diamond_confidence@1x.jpg')"
              :srcset="Images.get('/img/diamond_confidence@2x.jpg')"
              class="md:px-12"
              alt="Confidence"
            />
          </div>
          <div
            class="px-8 md:px-0 md:flex-1 text-center md:text-left grid justify-items-center"
          >
            <div class="my-auto">
              <headline class="md:text-left"
                >{{ $t("details.confidence.title") }}
              </headline>
              <p class="md:pr-8 text-gray-400 text-sm whitespace-pre-line">
                {{ $t("details.confidence.text") }}
              </p>
              <a
                href="/ueber_rubin/nachhaltigkeit/"
                class="uppercase text-sm border border-brand-main py-1 px-8 mt-4 inline-block text-brand-main hover:text-white hover:bg-brand-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-brand-main"
              >
                {{ $t("details.confidence.button") }}
              </a>
            </div>
          </div>
        </div>

        <div class="max-w-screen-lg mx-auto md:flex my-6 font-extralight">
          <div
            class="px-8 md:px-0 md:flex-1 flex flex-wrap justify-center md:order-last"
          >
            <img
              :src="Images.get('/img/diamond_gia@1x.jpg')"
              :srcset="Images.get('/img/diamond_gia@2x.jpg')"
              class="md:px-12"
              alt="Confidence"
            />
          </div>
          <div
            class="px-8 md:px-0 md:flex-1 text-center md:text-right grid justify-items-center md:order-first"
          >
            <div class="my-auto">
              <headline class="md:text-right md:pr-8"
                >{{ $t("details.giaEngraving.title") }}
              </headline>
              <p class="md:pr-8 text-gray-400 text-sm whitespace-pre-line">
                {{ $t("details.giaEngraving.text") }}
              </p>
              <a
                href="/diamanten/gia_zertifikat/"
                class="uppercase text-sm border border-brand-main py-1 px-8 mt-4 md:mr-8 inline-block text-brand-main hover:text-white hover:bg-brand-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-brand-main"
              >
                {{ $t("details.giaEngraving.button") }}
              </a>
            </div>
          </div>
        </div>

        <div class="max-w-screen-lg mx-auto md:flex my-6 font-extralight">
          <div class="px-8 md:px-0 md:flex-1 flex flex-wrap justify-center">
            <img
              :src="Images.get('/img/diamond_as_invest@1x.jpg')"
              :srcset="Images.get('/img/diamond_as_invest@2x.jpg')"
              class="md:px-12"
              alt="Diamanten als Wertanlage"
            />
          </div>
          <div
            class="px-8 md:px-0 md:flex-1 text-center md:text-left grid justify-items-center"
          >
            <div class="my-auto">
              <headline class="md:text-left"
                >{{ $t("details.investment.title") }}
              </headline>
              <p class="md:pr-8 text-gray-400 text-sm whitespace-pre-line">
                {{ $t("details.investment.text") }}
              </p>
              <a
                href="/diamanten/wertanlage/"
                class="uppercase text-sm border border-brand-main py-1 px-8 mt-4 inline-block text-brand-main hover:text-white hover:bg-brand-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-brand-main"
              >
                {{ $t("details.investment.button") }}
              </a>
            </div>
          </div>
        </div>

        <div class="relative bg-brand-bg mx-auto md:flex">
          <div
            class="px-0 md:flex-1 flex flex-wrap justify-center md:order-last"
          >
            <img
              src="https://www.rubin-trauringe.de/img/diamonds/gia/slider@2x.jpg"
              class="object-cover object-right"
              alt="Confidence"
            />
          </div>
          <div
            class="py-10 px-8 md:px-0 md:flex-1 text-center md:text-right grid justify-items-end md:order-first"
          >
            <div class="my-auto max-w-lg md:pr-8">
              <headline class="md:text-right">
                {{ $t("details.certificate.title") }}
              </headline>
              <p class="text-gray-400 text-sm">
                {{ $t("details.certificate.text") }}
              </p>
              <a
                href="/diamanten/gia_zertifikat/"
                class="uppercase text-sm border border-brand-main py-1 px-8 mt-4 inline-block text-brand-main hover:text-white hover:bg-brand-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-brand-main"
              >
                {{ $t("details.certificate.button") }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <unknown-diamond v-else-if="unknownDiamond" />
  </section>
</template>

<script>
import { ref, watch, defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import { Diamond } from "@/assets/ts/diamond";
import { useStore } from "vuex";
import DetailsCarat from "@/components/details/carat";
import DetailsColor from "@/components/details/color";
import DetailsClarity from "@/components/details/clarity";
import DetailsCut from "@/components/details/cut";
import DetailsFluorescence from "@/components/details/fluorescence";
import DetailsFullSpecs from "@/components/details/fullSpecs";
import Headline from "@/components/headline";
import SeparationLine from "@/components/separationLine";
import { Images } from "@/assets/ts/images";
import { PanchImages } from "@/assets/ts/PanchImages";
import { Certificate } from "@/assets/ts/certificate";
import NextStep from "@/components/details/nextStep";
import DetailsImage from "@/components/details/image";
import DetailsFullscreenImage from "@/components/details/fullscreenImage";
import DetailsFullscreenVideo from "@/components/details/fullscreenVideo";
import UnknownDiamond from "@/components/details/unknown";
import { diamondStructure } from "@/assets/ts/types/diamond";

export default defineComponent({
  name: "Diamond",
  components: {
    UnknownDiamond,
    DetailsFullscreenVideo,
    DetailsFullscreenImage,
    DetailsImage,
    SeparationLine,
    Headline,
    DetailsFluorescence,
    DetailsCut,
    DetailsClarity,
    DetailsColor,
    DetailsCarat,
    DetailsFullSpecs,
    NextStep,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const diamond = ref({});
    const showNextStepModal = ref(false);
    const imageFullscreenMode = ref(false);
    const videoFullscreenMode = ref(false);
    const unknownDiamond = ref(false);

    /**
     * Diamond
     */
    console.log(document.getElementById("diamondDetailsBox"));
    const getDiamond = (certificate) => {
      if (certificate !== "" && typeof certificate !== "undefined") {
        store.commit("loading/actionStarted");
        new Diamond()
          .get(certificate)
          .then((data) => {
            diamond.value = data;
            store.commit("loading/actionFinished");

            document.documentElement.scrollIntoView();

            if (certificate.substring(0, 3) === "rap") {
              console.log(diamond.value);
            } else {
              if (
                diamond.value.institute === "GIA" ||
                diamond.value.institute === "IGI"
              ) {
                const Panch = new PanchImages();
                Panch.getStill(diamond.value.certificate).then(
                  (externImageUrl) => {
                    if (externImageUrl !== "") {
                      diamond.value.video_url = Panch.get360(
                        diamond.value.certificate
                      );
                    }
                  }
                );
              }
            }
          })
          .catch(() => {
            unknownDiamond.value = true;
            diamond.value.video_url = "";
            store.commit("loading/actionFinished");
          });
      }
    };

    getDiamond(route.params.diamond);

    const certificateUrl = computed(() => {
      return Certificate.getUrl(
        diamond.value.institute,
        diamond.value.certificate
      );
    });

    watch(
      () => route.params.diamond,
      (current) => {
        getDiamond(current);
      },
      { deep: true }
    );

    /**
     * Favorites
     */

    const toggleFavorite = (certificate) => {
      store.commit("favorites/toggle", certificate);
    };

    const favorites = computed(() => {
      return store.getters["favorites/getList"];
    });

    /**
     * Scroll
     */

    const toConfidence = () => {
      document.getElementById("textConfidence").scrollIntoView();
    };

    /**
     * order
     */

    const createOrderData = (orderType) => {
      let itemNumber = (
        diamond.value.institute + diamond.value.certificate
      ).toUpperCase();

      if (typeof diamond.value.link != "undefined") {
        itemNumber = diamond.value.link.toUpperCase();
      }

      return btoa(
        JSON.stringify({
          fast_order: false,
          order_type: orderType,
          language: "de",
          rings: [
            {
              name: itemNumber,
              size: 0,
              gravure: "",
              gravure_type: 0,
              gravure_outside: "",
              engravingOutsideType: 0,
              item_data: {
                master: [itemNumber],
              },
              master_set: itemNumber,
              itemnumber: itemNumber,
            },
          ],
        })
      );
    };

    const orderDataDiamond = computed(() => {
      return createOrderData("diamond");
    });

    const orderDataJewelry = computed(() => {
      return createOrderData("jewelry");
    });

    return {
      diamond,
      toggleFavorite,
      favorites,
      Images,
      certificateUrl,
      showNextStepModal,
      imageFullscreenMode,
      videoFullscreenMode,
      unknownDiamond,
      toConfidence,
      orderDataDiamond,
      orderDataJewelry,
    };
  },
});
</script>

<style></style>
